import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1300px;
  margin: 40px auto;
  padding: 0 20px;
`;

export const ProjectList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    justify-content: center;
  }
  @media (max-width: 576) {
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Project = styled.div`
  width: 33%;
  padding: 10px;
  transition: 0.6s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 992px) {
    width: 50%;
  }
`;

export const Title = styled.h2`
  color: ${props => props.theme.primary};
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  margin-top: 10px;
  color: ${props => props.theme.primary};
`;
