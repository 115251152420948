import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../../components/Seo';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Image from '../../components/Image';

import { Container, ProjectList, Project, Title, Description } from './styles';

export default function Portfolio() {
  const intl = useIntl();

  const projects = [
    {
      id: 1,
      title: 'Placar Real',
      description: 'ReactJS',
      cover: 'projects/placar-real.jpg',
    },
    {
      id: 2,
      title: 'Consultar CNPJ',
      description: 'ReactJS, Gatsby',
      cover: 'projects/consultar-cnpj.jpg',
    },
    {
      id: 3,
      title: 'Guia Anúncios',
      description: 'PHP, Laravel',
      cover: 'projects/guia-anuncios.jpg',
    },
    {
      id: 4,
      title: 'Avenida Shopping Office',
      description: 'PHP, Wordpress',
      cover: 'projects/avenida-shopping.jpg',
    },
    {
      id: 5,
      title: 'Vanessa Costa Fotografias',
      description: 'PHP, Wordpress',
      cover: 'projects/vanessa-fotografias.jpg',
    },
    {
      id: 6,
      title: 'Cuesta Café',
      description: 'PHP, Wordpress',
      cover: 'projects/cuesta-cafe.jpg',
    },
    {
      id: 7,
      title: 'SmartShare Educação',
      description: 'PHP, Wordpress',
      cover: 'projects/educacao-smartshare.jpg',
    },
  ];

  return (
    <>
      <SEO title={intl.formatMessage({ id: 'pages.portfolio.title' })} />
      <Header />

      <Container>
        <ProjectList>
          {projects.map(project => (
            <Project>
              <Title>{project.title}</Title>
              <Image filename={project.cover} />
              <Description>{project.description}</Description>
            </Project>
          ))}
        </ProjectList>
      </Container>

      <Footer>
        <p>前橋市、群馬県、日本, {new Date().getFullYear()} - Thiago da Paz</p>
      </Footer>
    </>
  );
}
